export default [
    { time: 0, text: "Hello everyone, welcome to the CIS user manual " },
    { time: 3, text: "Here is our main page we enter email and password in this section and sign into the system. " },
    { time: 9, text: "If you've forgotten your password, you can click on forgot my password and enter your email address for the password to be sent to your email." },
    { time: 16, text: "Right now we're looking at the system as a department manager. Here, if the users requested a change, an update is sent to the manager and it looks like this on their main page. For example here we have an historical change request and we have another one here.  " },
    
    { time: 32, text: "The manager can approve or decline from here and the person receives an email about the response. " },
    { time: 40, text: "And we have to reports for the week here. You can see how many times tasks are assigned or which day they are assigned for from here. " },
    { time: 48, text: "The main page looks the same for admins except the request section. We can see the task reports here as we've seen in the department managers view. " },
    { time: 58, text: "Admins can see the settings page here. " },

    { time: 61, text: "This is the first page they should set before using the system. " },
    { time: 63, text: "In the settings we have six sections." },

    { time: 66, text: "We can add the roles from edit permissions. We click on add a new role and type the role name. For example Training Employee and then we can put a role description. Then, we can put the permissions we'd like to give. We can pick anything from these sections and then when we save, all the users that have these roles will only have these permissions. " },
    { time: 106, text: "In the bank holidays section, we see all the holidays as a list. This gets added to the calendar and on that date a task is created as 'Office Closed'. " },

    { time: 120, text: "We also need to create a task from here. We click on add task and then add the name of the task and then we assign the roles. We can also pick a color for the task. " },
    { time: 136, text: "As you can see there is an exclamation mark here: Task assignment Rota Builder function will be limited to roles specified here. The limitations won't apply to manual changes in Rota. So it means that when it is created from the Rota Builder randomly, the tasks will only be assigned to the users with these roles. " },
    { time: 155, text: "We can also create departments here. We can click on Add Department, or Edit or Delete Department. " },
    { time: 161, text: "Connected to the departments, we can also create a Site. Here, when we are creating a site, we pick a department and then we add the name of the site. And we can also enter the opening and closing times. And then we click on Save.  " },

    { time: 178, text: "Our last section here is the Actions Timeline. Here we see all the logins of all the users. For example we also see when something has been updated or something has been deleted. Basically everything gets saved here.  " },
    
    
    { time: 202, text: "Now lets go to the users page. In the users page, if we click on the three dots on the side, we can see the details of the user. As you can see, these are all the details of this user. " },

   
    { time: 219, text: "And if we go to the schedule, we can do everything we were able to do in the main calendar. For example we can right click and add a new task, change time or delete the task. Or we can click on it and change the task as well. We can download this as a PDF and get a report of it. Or we can duplicate the week. When we click on duplicate the week and pick a week, we can copy this week to another week that we choose here. We'll talk about this in more detail later in the video. " },
    

    { time: 258, text: "Here we also have time off. When users add a time off, they can pick a range or they can double click the day and choose that day, and then click on add. " },
    { time: 273, text: "Here, we also have the report. We have the report of this week, this month, last month etc. " },


    { time: 288, text: "In the user section, lets pick a user again. Here as you can see we also have a duplicate button here with this we can duplicate the user but the email address will have the number one at the end. The user will need to change the email to be able to login. When a user is deactivated they can't log into the site again. The user can also send a new password. " },
    
    { time: 317, text: "Now, let's go to the tool section. In the tool section, we have all the users listed and we also have filters here that we can use. We can pick the department and can see all the users in that department and only the sites of those departments. We can also filter the site, the role, the staff and a time. For example if we pick Not Working here, on Monday, then will only see the non-working users and the rest will disappear. " },
    { time: 378, text: "Now let's clear this. Other than the filtering, if we right click, we can add a new task, change the time, or delete the task. If we see free time slots we can add a task and put a time and save. We can also drag and drop the tasks. I'm not going to change anything right now. " },
    { time: 416, text: "We also have a hide mode here when we turn on the hide mode turn on the left side. In this Hidden Tasks we are able to hide a person and not see on this list again. If we click on the show hidden button here we can see the hidden people and if we want to add them to the actual calendar, we click on unhide.  " },
    
    { time: 450, text: "We have a rota builder function at the top here. From here we can pick staff, but if we don't we can go to General and assign tasks to the departments randomly. After we pick the user here, we can pick a task and then the department and we can enter the date and click on a.m. or p.m. or enter the time and when we click on generate rota, this will be assigned. " },
    { time: 476, text: "The most important aspect of this section is the general section we can pick multiple departments here, enter the date, and the task. We can also add a role and the tasks prepared from the settings will be assigned to these departments randomly. " },

    
    { time: 498, text: "We also have Create Routine. This is something similar but here we need to choose the task and departments. As you can see it says required here as well. We also choose the routine period. And since this will be a routine, we can pick specific days. What's different here is since this will be a routine, we can just pick the day and arrange the time. Rota builder is more general in terms of the date compared to this one. Since this is a routine, the details of the date is the most important thing to arrange. As you can see we also have a summary here.   " },
    
    { time: 538, text: "In the duplicate week function we can pick copy source and choose the departments. For example if we want to do the same thing next year on this week we pick the Target here and fill in the other sections. We can also pick staff here, but if you don't, all staff roles and departments will be duplicated. We can also choose the dates, as you can see we have the summary here. So the week 25, will be copied to 38,39 and 40. We can also pick multiple weeks here, and as the summary says, 25 will go to 38, 26 will go to 39 and 27 will go to 40 and we click on Duplicate Week.    " },
    { time: 599, text: "We also have shortcut buttons here. We can select all and choose all the weeks on this year and as you can see there is a summary of how it will be here. We can select past, select future or Clear Selection. And we can click on Download a pdf version and have the pdf version of the chosen week. If we've chosen a filter, it will be downloading the filtered version, so it will be downloading what's on the screen. And that's all we have for the Tool section. " },


    { time: 651, text: "The last section we have is the report section. " },

    { time: 655, text: "Here we have our summary report, we have clinic and time report. The clinic report shows how many tasks are assigned and if you go to time report this shows how many hours are worked. We can filter it this week, this month, last month or we can filter it here. We also have the task breakdown here. We're able to filter from here, the default is the filtered departments, we can also pick specific departments we're also able to download it as a PDF or we can export this in an Excel version. " },
    { time: 746, text: "Here, we also have a separate report for the tasks. Here we show all the tasks, and we can do department or task filters. We can also download this as a PDF, and you'll again see it the same way as you see on the screen.   " },
    { time: 784, text: "In the user report, we see all the users first but we can filter it the same way. We can pick the Departments, the tasks, or the roles. We can filter the date and we're able to download this as a PDF the same way" },
    { time: 812, text: "That's all we have for the user manual. " },


    // Add more entries as needed






  ];